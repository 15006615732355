import React from "react";
import { Link } from "react-router-dom";

//Components
import Header from "../components/Header";
import Layout from "../Layout";
import BgImage from "../components/BgImage";

//Assets
import home from "../img/icons/home.svg";
import issues from "../img/icons/reports.svg";
import news from "../img/icons/news.svg";
import advice from "../img/icons/advice.svg";
import admins from "../img/icons/admins.svg";
import stats from "../img/icons/stats.svg";

const items = [
  {
    title: "Početna",
    icon: home,
    path: "/dashboard",
    description: "Početna strana",
  },
  {
    title: "Prijave",
    icon: issues,
    path: "/issues",
    description: "Na ovoj strani možete pogledati listu prijava",
  },
  {
    title: "Novosti",
    icon: news,
    path: "/posts",
    description: "Na ovoj strani možete naći pregled novosti",
  },
  {
    title: "Savjeti",
    icon: advice,
    path: "/advice",
    description: "Na ovoj strani možete pronaći listu savjeta",
  },
  {
    title: "Korisnici",
    icon: admins,
    path: "/users",
    description: "Na ovoj stranici možete pogledati listu korisnici",
  },
  {
    title: "Statistika",
    icon: stats,
    path: "/statsByAge",
    description:
      "Na ovoj stranici možete pogledati listu statističkih podataka",
  },
];

const Dashboard = () => {
  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto mt-20">
        <div className="grid grid-cols-3 gap-6">
          {items.map((item, idx) => {
            return (
              <Link to={item.path}>
                <div className="shadow-lg rounded-2xl p-4 bg-white h-64">
                  <div className="flex flex-col items-center justify-center h-full">
                    <img src={item.icon} className="w-16 h-16" alt="Icon" />
                    <p className="text-custom text-xl font-medium mb-4 mt-4">
                      {item.title}
                    </p>
                    <p className="text-gray-500 text-base text-center">
                      {item.description}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;
