import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { Doughnut } from "react-chartjs-2";

const StatisticsByGender = ({ countIssues }) => {
  const [genderCounts, setGenderCounts] = useState([0, 0]);
  const state = {
    labels: ["Muški", "Ženski", "Ne izjašnjavam se"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: ["#00408A", "#db3e3e", "#002857"],
        hoverBackgroundColor: ["#002857", "#c22424"],
        data: genderCounts,
      },
    ],
  };

  useEffect(() => {
    statsByGender();
  }, []);

  const statsByGender = async () => {
    let male = await countIssues({ "reporter.gender": "Male" });
    let female = await countIssues({ "reporter.gender": "Female" });
    let unspecified = await countIssues({ "reporter.gender": "Unspecified" });
    setGenderCounts([male, female, unspecified]);
  };

  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 mb-10 uppercase">
          Statistika o polu
        </div>
        <Doughnut
          data={state}
          options={{
            legend: {
              display: true,
              position: "top",
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
};

export default connect(null, mapDispatchToProps)(StatisticsByGender);
