import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const IssueList = ({ issues, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="py-8">
          <div className="shadow rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Redni br.
                  </th>
                  {/* <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Prijava
                  </th> */}
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Prijavljeno
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Kompanija
                  </th>

                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Djelatnost
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Povreda prava
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Obnovljeno
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Pogledaj detalje
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {issues.map((item, idx) => {
                  // let reportType = "";
                  // let type = item.reportType;
                  // if (type === "request") {
                  //   reportType = "Zahtjev";
                  // } else if (type === "complaint") {
                  //   reportType = "Pritužba";
                  // } else {
                  //   reportType = "Prekršaj";
                  // }

                  let reportStatus = "";
                  let status = item.status;
                  if (status === "reported") {
                    reportStatus = "Prijavljeno";
                  } else if (status === "in_progress") {
                    reportStatus = "Postupak u toku";
                  } else {
                    reportStatus = "Zatvoreno";
                  }
                  return (
                    <tr {...props}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {idx + 1}
                        </p>
                      </td>
                      {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportType}
                        </p>
                      </td> */}
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.selfReport === true ? "Za sebe" : "Za drugima"}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.company}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.industry.name}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.right_category.title}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {reportStatus}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {moment(item.updated_at).format("DD.MM.YYYY")}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link
                          to={`/issueDetails/${item.id}`}
                          className="text-blue"
                        >
                          Pogledaj detalje
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueList;
